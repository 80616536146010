<template>
	<div>
		<div class="form-wrapper page-has-nav">
			<div class="form-container">
				<div class="input-box">Fine Grounds shows all of the items on your Square Point-of-Sale system as available for purchase by default. If you want any specific items to be excluded from your shop on Fine Grounds, you can do so by deselecting the item or items below.</div>
			</div>
			<div class="make-item-management-grid">
				<div v-for="(category) in categories" :key="`category-${ category }`" class="form-container">
					<form action @submit.prevent="onSubmit">

						<form-errors :errors="errors"></form-errors>

						<div class="simple-title">{{ category || 'Non-Categorized' }}</div>

						<div v-for="item in itemsByCategory[category]" :key="`item-${ item.id }-${ category }`" class="input-container">
							<div class="input-checkbox">
								<div class="checkmark-container">
									<label class="checkbox">
										<input type="checkbox" :id="item.id" v-model="item.canBePurchased">
										<span class="checkmark"></span>
									</label>
								</div>
								<label :for="item.id" class="input-checkbox-text">{{ item.name }}</label>
							</div>
						</div>
						<br>
						<submit-button text="Save" icon="fa-save" :loading="formSaving" :has-errors="hasErrors"></submit-button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import _ from 'underscore';
import FormMixin from '@/mixins/FormMixin';

export default {

	name: 'ManageItemsPage',

	mixins: [ FormMixin ],

	async asyncData({ store, app }) {
		try {
			const { data } = await app.$api.get('/business/items');

			return {
				items: data.data,
			};
		} catch (e) {
			store.dispatch('errorToast', 'Unable to load items');
		}
	},

	data() {
		return {
			items: [],
		};
	},

	computed: {

		itemsByCategory() {
			return _.groupBy(this.items, 'category');
		},

		categories() {
			return _.uniq(_.pluck(this.items, 'category'));
		},

	},

	methods: {

		async onSubmit() {
			this.submit(async () => {
				await this.$api.post('/business/items', { items: this.items });

				this.$store.dispatch('addToast', 'Item availability updated');
			}, () => {
				this.$store.dispatch('errorToast', 'Unable to update availability');
			});
		},

	},


};

</script>
